/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/index.scss";
export const onInitialClientRender = () => {
  // Ensure the page is scrolled to the top on reload
  window.scrollTo(0, 0);
};
